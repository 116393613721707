import React, { useState, useEffect, useRef, useMemo } from "react";
import axios from "axios";
import { useWebSocket } from "./WebSocketProvider"; // Import the useWebSocket hook
import JSONAutocomplete from "json-autocomplete";
import { processChunk } from "./chunkManagement"; // Import the useWebSocket hook
// Import the replay buffer
import replayBuffer from "./replayBuffer.json";

// TODO
// Clear conversation when the active conversation is deleted
// Create a settings page, and create a way to modify mastery threshold
function ConversationArea({
  showTransliteration,
  language,
  conversationDetails,
  setConversationDetails,
  initialConversationDetails,
  setInitialConversationDetails,
  masteryThreshold,
  setMasteryThreshold,
}) {
  const [activeMessageId, setActiveMessageId] = useState(null);
  const [wordBank, setWordBank] = useState([]);
  const [localWordBank, setLocalWordBank] = useState([]);
  const [newMessageIds, setNewMessageIds] = useState([]);
  const [expandedMessageId, setExpandedMessageId] = useState(null);
  const [formattedConversationHistory, setFormattedConversationHistory] =
    useState([]);
  const [selectedMorphemes, setSelectedMorphemes] = useState([]);

  const { ws } = useWebSocket();
  useEffect(() => {
    // OPTIMIZE: This should only be run once and create a list of old ids instead
    if (!conversationDetails || !initialConversationDetails) return;
    const initialIds = new Set(
      initialConversationDetails.conversationHistory.map((msg) => msg.id)
    );
    const newIds = conversationDetails.conversationHistory
      .map((msg) => msg.id)
      .filter((id) => !initialIds.has(id));
    setNewMessageIds(newIds);
  }, [conversationDetails]);

  useEffect(() => {
    // Process the conversation history and update localWordBank
    const processConversation = () => {
      // Create a shallow copy of wordBank to avoid direct mutations
      const updatedWordBank = wordBank.map((word) => ({ ...word }));
      initialConversationDetails.conversationHistory.forEach((message) => {
        const parsedDetails = parseMorphemeTranslationDetails(message);
        if (!parsedDetails) return;

        Object.values(parsedDetails).forEach((morpheme) => {
          const originalMorpheme = morpheme[0];
          if (!originalMorpheme) return;

          decrementEffectiveCount(updatedWordBank, originalMorpheme);
        });
      });

      setLocalWordBank(updatedWordBank);
    };

    // Helper function to decrement effectiveCount for a given morpheme
    const decrementEffectiveCount = (bank, originalMorpheme) => {
      const word = bank.find(
        (word) => word.originalMorpheme === originalMorpheme
      );
      if (!word) {
        //console.warn(`Morpheme "${originalMorpheme}" not found in wordBank.`);
        return;
      }

      if (word.effectiveCount <= 0) {
        /*         console.warn(
          `effectiveCount for "${originalMorpheme}" is already zero.`
        ); */
        return;
      }

      word.effectiveCount -= 1;
    };

    processConversation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wordBank, initialConversationDetails]);

  // Helper function to parse morphemeTranslationDetails
  const parseMorphemeTranslationDetails = (message) => {
    if (!message.morphemeTranslationDetails) {
      /*console.warn(
        "morphemeTranslationDetails is missing in the current message."
      ); */
      return null;
    }
    try {
      return JSON.parse(message.morphemeTranslationDetails);
    } catch (error) {
      console.error("Failed to parse morphemeTranslationDetails:", error);
      return null;
    }
  };
  const normalizeMorpheme = (morpheme) => {
    return morpheme
      .replace(/[^\p{L}\s]/gu, "") // Remove non-letter characters except whitespace
      .trim();
  };

  const getSeenCount = (word, morphemeList) => {
    const normalizedWord = normalizeMorpheme(word);
    // Find the morpheme in the localWordBank
    const morpheme = localWordBank.find(
      (morpheme) => morpheme.originalMorpheme === normalizedWord
    );
    // Get the effectiveCount from localWordBank
    const localCount = morpheme ? morpheme.effectiveCount : 0;

    // Get the count from morphemeList
    const listCount = morphemeList[normalizedWord] || 0;

    // Return the combined count
    return localCount + listCount;
  };

  const calculateOpacity = (seenCount) => {
    const opacity = 1 - Math.min(seenCount / masteryThreshold, 1);
    return opacity;
  };

  // Function to format the conversation history
  const formatConversationHistory = () => {
    if (
      !conversationDetails ||
      !Array.isArray(conversationDetails.conversationHistory)
    ) {
      console.error("Invalid conversation details provided.");
      return;
    }
    const morphemeList = {};

    const formattedHistory = conversationDetails.conversationHistory.map(
      (message) => {
        // Parse the morphemeTranslationDetails using the helper function
        const parsedMorphemeDetails = parseMorphemeTranslationDetails(message);

        if (!parsedMorphemeDetails) {
          // If parsing failed or details are missing, return the message with an empty morphemeArray
          return {
            ...message,
            morphemeArray: [], // Provide an empty default morphemeArray
          };
        }

        // **Processing Section: Create an array of morpheme objects**
        const morphemeArray = Object.entries(parsedMorphemeDetails)
          .filter(([_, value]) => value && typeof value === "object") // Filter out invalid entries
          .map(([key, value], index) => {
            if (Array.isArray(value) && value.length === 3) {
              const [
                originalMorpheme,
                translatedMorpheme,
                transliteratedMorpheme,
              ] = value;

              // **Morpheme List Processing: Increment count for originalMorpheme**
              if (morphemeList.hasOwnProperty(originalMorpheme)) {
                morphemeList[originalMorpheme] += 1;
              } else {
                morphemeList[originalMorpheme] = 1;
              }

              const morphemeKey = `morpheme-${key}-${index}`;
              const seenCount = getSeenCount(originalMorpheme, morphemeList);
              const opacity = calculateOpacity(seenCount);
              const isSelected = selectedMorphemes.includes(originalMorpheme);
              return {
                key: morphemeKey,
                originalMorpheme,
                translatedMorpheme,
                transliteratedMorpheme,
                opacity,
                isSelected,
              };
            }
            // Return null for entries that don't match the expected structure
            return null;
          })
          .filter((morpheme) => morpheme !== null); // Remove null entries

        // Return a new message object with the processed morphemeArray
        return {
          ...message, // Spread the original message properties
          morphemeArray, // Add the processed morpheme array
        };
      }
    );
    // Update the formatted conversation history state
    setFormattedConversationHistory(formattedHistory);
  };

  useEffect(() => {
    formatConversationHistory();
  }, [
    conversationDetails,
    masteryThreshold,
    selectedMorphemes,
    wordBank,
    localWordBank,
  ]); // Re-run when conversationDetails changes

  useEffect(() => {
    if (!ws) return;
    const onMessage = async (event) => {
      const data = JSON.parse(event.data);
      if (data.conversationDetails) {
        setConversationDetails(data.conversationDetails);
        setInitialConversationDetails(data.conversationDetails);
      }

      // Early returns for cleaner code
      if (!data.key || data.chunkId === undefined || !data.subkey) return;
      // Instead of an early return, this could sort the id's and run the code for each id.

      const temporaryChunkStorage = await processChunk(data); // Await the async function, adjust processChunk to accept an object
      if (!temporaryChunkStorage) return;

      updateMessageInHistory({
        chunkData: data,
        temporaryChunkStorage,
      });
    };

    function updateMessageInHistory({ chunkData, temporaryChunkStorage }) {
      try {
        const messageId = chunkData.key;
        const target = chunkData.subkey;
        //console.log("chunkData : ", chunkData);
        if (!temporaryChunkStorage && temporaryChunkStorage.chunks) {
          console.error(
            `No temporaryChunkStorage found for key: ${messageId}, subkey: ${target}`
          );
          return;
        }

        // Sort chunkIds numerically to ensure correct order
        const sortedChunkIds = Object.keys(temporaryChunkStorage.chunks)
          .map(Number)
          .sort((a, b) => a - b);
        // Combine chunks based on sorted order
        const combinedData = sortedChunkIds
          .map((chunkId) => temporaryChunkStorage.chunks[chunkId].chunk)
          .join("");
        switch (target) {
          case "targetLanguageMessage":
            updateMessageInConversation(messageId, target, combinedData);
            break;
          case "morphemeTranslationDetails":
            try {
              const completeJsonString = JSONAutocomplete(combinedData);

              // Validate that completeJsonString is valid JSON
              const parsedMorphemeTranslationDetails =
                JSON.parse(completeJsonString);

              // Check if parsedMorphemeTranslationDetails is an object and not empty
              const isEmptyObject =
                parsedMorphemeTranslationDetails &&
                typeof parsedMorphemeTranslationDetails === "object" &&
                !Array.isArray(parsedMorphemeTranslationDetails) &&
                Object.keys(parsedMorphemeTranslationDetails).length === 0;

              if (isEmptyObject) {
                break;
              }

              // If it's an array, you might want to check its length
              const isEmptyArray =
                Array.isArray(parsedMorphemeTranslationDetails) &&
                parsedMorphemeTranslationDetails.length < 1;

              if (isEmptyArray) {
                break;
              }

              // If it's neither an empty object nor an empty array, proceed
              updateMessageInConversation(
                messageId,
                target,
                completeJsonString
              );
            } catch (error) {
              // Handle the error appropriately
              //console.error("Error parsing JSON:", error);
            }
            break;

          case "fullSentenceTranslation":
            updateMessageInConversation(messageId, target, combinedData);
            break;
          // Add more cases as needed for different targets
          default:
            // Your default case code here
            //console.warn(`Unrecognized target: ${target}`);
            // You could handle unexpected targets or perform a generic operation here
            break;
        }
      } catch (error) {
        console.error("error: ", error);
      }
    }

    ws.addEventListener("message", onMessage);
    return () => {
      ws.removeEventListener("message", onMessage);
    };
  }, [ws]);
  // Initialize a ref to store recorded actions
  //const recordedActions = useRef([]);
  const updateMessageInConversation = (messageId, field, value) => {
    /*     console.log("messageId : ", messageId);
    console.log("field : ", field);
    console.log("value : ", value); */
    setConversationDetails((prevDetails) => {
      const messageExists = prevDetails.conversationHistory.some(
        (message) => message.id === messageId
      );

      if (messageExists) {
        // If the message exists, update the specific field
        return {
          ...prevDetails,
          conversationHistory: prevDetails.conversationHistory.map((message) =>
            message.id === messageId ? { ...message, [field]: value } : message
          ),
        };
      } else {
        // If the message does not exist, create a new message
        const newMessage = {
          id: messageId,
          [field]: value,
          // Add any other default fields if necessary
          // For example:
          // timestamp: new Date().toISOString(),
          // sender: 'User',
        };

        return {
          ...prevDetails,
          conversationHistory: [...prevDetails.conversationHistory, newMessage],
        };
      }
    });
    // Record the action by pushing it into the recordedActions array
    /*     recordedActions.current.push({
      messageId,
      field,
      value,
      timestamp: Date.now(), // Optional: Add a timestamp for replay sequencing
    });
    console.log("recordedActions.current", recordedActions.current); */
  };

  // Function to start replaying actions
  const replayActions = (delay = 1000) => {
    // Default delay of 1000ms
    replayBuffer.forEach((action, index) => {
      setTimeout(() => {
        updateMessageInConversation(
          action.messageId,
          action.field,
          action.value
        );
      }, delay * index);
    });
  };

  // Example usage: Start Replay with a specified delay
  const handleReplay = () => {
    setConversationDetails({
      conversationId: null,
      conversationHistory: [],
    });
    setInitialConversationDetails({
      conversationId: null,
      conversationHistory: [],
    });
    const delay = 10; // 1 second delay between actions
    replayActions(delay);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      const optionsContainer = document.querySelector(
        ".message-options-container"
      );
      if (!optionsContainer) return;
      if (!optionsContainer.contains(event.target)) {
        setActiveMessageId(null);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    // Must be mousedown. Checking click causes it to close prematurely
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []); // Ensure dependencies are correctly set if any

  const fetchMorphemes = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/get-morphemes`,
        {
          withCredentials: true,
        }
      );
      // Sort the data by count in descending order
      const sortedData = response.data.morphemes.sort(
        (a, b) => b.trueCount - a.trueCount
      );
      const masteryThreshold = response.data.masteryThreshold;
      setMasteryThreshold(masteryThreshold);
      setWordBank(sortedData);
      return sortedData;
    } catch (error) {
      console.error("Error fetching morphemes:", error);
      console.error("Error details:", error.response);
      throw error;
    }
  };
  useEffect(() => {
    fetchMorphemes();
  }, [initialConversationDetails]);

  const handleFullSentenceTranslation = async () => {
    if (!activeMessageId) return;
    setActiveMessageId(null); // Close the menu

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/translate-sentence`, {
        messageId: activeMessageId,
        language,
      });
    } catch (error) {
      console.error("Error translating sentence:", error);
    }
  };

  const handleWordOptions = (messageId) => {
    setActiveMessageId(null); // Close the menu
    if (expandedMessageId === messageId) {
      // Collapse if the same message is clicked again
      setExpandedMessageId(null);
    } else {
      setExpandedMessageId(messageId);
    }
  };
  const renderMessageOptions = () => {
    return (
      <div
        className={`message-options-container ${
          activeMessageId ? "visible" : ""
        }`}
      >
        <md-outlined-button
          className="button"
          onClick={handleFullSentenceTranslation}
          title="Translate Message"
          aria-label="Translate Message"
        >
          <md-icon style={{ transform: "translateY(11.5%)", fontSize: "1rem" }}>
            translate
          </md-icon>
          Full Sentence Translation
        </md-outlined-button>

        <md-outlined-button
          className="button"
          onClick={() => handleWordOptions(activeMessageId)}
          title="Word Options"
          aria-label="Word Options"
        >
          <md-icon style={{ transform: "translateY(11.5%)", fontSize: "1rem" }}>
            build_circle
          </md-icon>
          Word Options
        </md-outlined-button>
      </div>
    );
  };

  return (
    <div className="conversation-area">
      {/*<button onClick={handleReplay} style={{ position: "absolute" }}>
        Start Replay
      </button>*/}

      <div className="scroll-wrapper">
        <div className="message-list">
          {formattedConversationHistory.map((message) => (
            <MessageContainer
              key={message.id}
              isExpanded={expandedMessageId === message.id}
              {...{
                message,
                showTransliteration,
                setActiveMessageId,
                language,
                wordBank,
                setWordBank,
                newMessageIds,
                setExpandedMessageId,
                masteryThreshold,
                selectedMorphemes,
                setSelectedMorphemes,
                fetchMorphemes,
              }}
            />
          ))}
        </div>
      </div>
      {activeMessageId !== null && renderMessageOptions()}
    </div>
  );
}
function MessageContainer({
  message,
  showTransliteration,
  setActiveMessageId,
  language,
  wordBank,
  setWordBank,
  newMessageIds,
  isExpanded,
  setExpandedMessageId,
  masteryThreshold,
  selectedMorphemes,
  setSelectedMorphemes,
  fetchMorphemes,
}) {
  const [showMorphemeTranslationDetails, setShowMorphemeTranslationDetails] =
    useState(true);

  const toggleMorphemeTranslationDetails = () => {
    // Disable toggling if isExpanded is true
    if (isExpanded) return;
    setShowMorphemeTranslationDetails((prev) => !prev);
  };

  // Refs for managing press state
  const pressTimerRef = useRef(null);
  const isLongPressRef = useRef(false);
  const isClickEventRef = useRef(false);
  const isScrollingRef = useRef(false);
  const initialPointerPosRef = useRef({ x: 0, y: 0 });

  // Movement threshold in pixels (vertical only)
  const MOVE_THRESHOLD_VERTICAL_MOUSE = 10;
  const MOVE_THRESHOLD_VERTICAL_TOUCH = 20;

  // Ref to store current movement threshold
  const moveThresholdRef = useRef(MOVE_THRESHOLD_VERTICAL_MOUSE);

  // Cleanup timer on unmount
  useEffect(() => {
    return () => {
      clearTimeout(pressTimerRef.current);
    };
  }, []);

  // Start the long press timer and record initial position
  const handlePressStart = (event) => {
    // Only respond to primary button (usually left mouse button) or touch/pen
    if (event.pointerType === "mouse" && event.button !== 0) return;

    event.stopPropagation();

    // Reset scrolling flag on new press
    isScrollingRef.current = false;
    isClickEventRef.current = true;
    isLongPressRef.current = false;

    // Record initial pointer position
    initialPointerPosRef.current = { x: event.clientX, y: event.clientY };

    // Determine vertical movement threshold based on pointer type
    const moveThreshold =
      event.pointerType === "touch"
        ? MOVE_THRESHOLD_VERTICAL_TOUCH
        : MOVE_THRESHOLD_VERTICAL_MOUSE;

    // Store the threshold in a ref for access in handlePointerMove
    moveThresholdRef.current = moveThreshold;

    pressTimerRef.current = setTimeout(() => {
      if (!isScrollingRef.current) {
        isLongPressRef.current = true;
        setActiveMessageId(message.id); // Set active message on long press
      }
    }, 500); // Long press duration
  };

  // Handle pointer movement with vertical threshold
  const handlePointerMove = (event) => {
    const { y } = initialPointerPosRef.current;
    const deltaY = Math.abs(event.clientY - y);
    const currentMoveThreshold = moveThresholdRef.current;

    if (deltaY > currentMoveThreshold) {
      isScrollingRef.current = true;
      clearTimeout(pressTimerRef.current); // Cancel long press if moving beyond threshold
    }
  };

  // Handle pointer cancel (e.g., touch cancel)
  const handlePointerCancel = () => {
    // Reset all flags and clear timer
    isClickEventRef.current = false;
    isLongPressRef.current = false;
    isScrollingRef.current = false;
    clearTimeout(pressTimerRef.current);
  };

  // Handle press end
  const handlePressEnd = (event) => {
    // Only respond to primary button or touch/pen
    if (event.pointerType === "mouse" && event.button !== 0) return;

    event.stopPropagation();
    clearTimeout(pressTimerRef.current);

    if (
      !isLongPressRef.current &&
      isClickEventRef.current &&
      !isScrollingRef.current
    ) {
      toggleMorphemeTranslationDetails();
    }

    // Reset refs
    isClickEventRef.current = false;
    isLongPressRef.current = false;
    isScrollingRef.current = false;
  };

  const handleContextMenu = (event) => {
    event.preventDefault();
  };

  return (
    <div
      className={`message ${message.sender} ${
        isExpanded ? "message-expanded" : ""
      }`} // Apply the expanded class conditionally
      onPointerDown={handlePressStart}
      onPointerUp={handlePressEnd}
      onPointerMove={handlePointerMove}
      onPointerCancel={handlePointerCancel} // Handle pointer cancel events
      style={{ userSelect: "none" }} // Optional: Prevent text selection during long press
      onContextMenu={handleContextMenu}
    >
      <MessageComponent
        key={message.id}
        /* This is shorthand for passing props with the same name */
        {...{
          message,
          showTransliteration,
          showMorphemeTranslationDetails,
          language,
          wordBank,
          setWordBank,
          newMessageIds,
          isExpanded,
          selectedMorphemes,
          setSelectedMorphemes,
          masteryThreshold,
        }}
      />
      {isExpanded && (
        <MorphemeMenu
          selectedMorphemes={selectedMorphemes}
          setExpandedMessageId={setExpandedMessageId}
          fetchMorphemes={fetchMorphemes}
        />
      )}
    </div>
  );
}

function MessageComponent({
  message,
  showTransliteration,
  showMorphemeTranslationDetails,
  language,
  wordBank,
  isExpanded,
  selectedMorphemes,
  setSelectedMorphemes,
  masteryThreshold,
}) {
  useEffect(() => {
    if (isExpanded) return;
    setSelectedMorphemes([]);
  }, [isExpanded]);

  return (
    <span>
      {message.originalMessage && (
        <div className="original-message shared-message-content">
          <span>{message.originalMessage}</span>
        </div>
      )}

      {showMorphemeTranslationDetails && message.morphemeTranslationDetails ? (
        <div className={`shared-message-content`}>
          <MorphemesComponent
            message={{ ...message, language }}
            showTransliteration={showTransliteration}
            isExpanded={isExpanded}
            selectedMorphemes={selectedMorphemes}
            setSelectedMorphemes={setSelectedMorphemes}
            wordBank={wordBank}
            masteryThreshold={masteryThreshold}
            language={language}
          />
        </div>
      ) : (
        <div className="message-text shared-message-content">
          {message.targetLanguageMessage}
        </div>
      )}
      {message.fullSentenceTranslation && (
        <div className="full-sentence-translation shared-message-content">
          {message.fullSentenceTranslation}
        </div>
      )}
    </span>
  );
}

const MorphemeMenu = ({
  selectedMorphemes,
  setExpandedMessageId,
  fetchMorphemes,
}) => {
  const handleMarkAsMastered = async () => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/update-morphemes-as-mastered`,
        { originalMorphemes: selectedMorphemes },
        {
          withCredentials: true,
        }
      );
      await fetchMorphemes(); // Ensure fetchMorphemes completes before proceeding
    } catch (error) {
      console.error("Error marking morphemes as mastered:", error);
    }
  };

  const handleRefreshMastery = async () => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/update-morphemes-as-not-mastered`, // This is the endpoint you provided earlier
        { originalMorphemes: selectedMorphemes },
        {
          withCredentials: true,
        }
      );
      fetchMorphemes();
    } catch (error) {
      console.error("Error refreshing morpheme mastery:", error);
    }
  };

  const handleCancel = () => {
    setExpandedMessageId(null);
    // Add logic here for canceling the action or closing the menu
  };
  return (
    <div className="morpheme-menu">
      <md-outlined-button onClick={handleMarkAsMastered}>
        Mark as Mastered
      </md-outlined-button>
      <md-outlined-button onClick={handleRefreshMastery}>
        Refresh Mastery
      </md-outlined-button>
      <md-outlined-button onClick={handleCancel}>Cancel</md-outlined-button>
    </div>
  );
};
const MorphemesComponent = ({
  message,
  showTransliteration,
  isExpanded,
  setSelectedMorphemes,
  language,
}) => {
  const isRtlLanguage = (language) => {
    const rtlLanguages = [
      "Arabic",
      "Hebrew",
      "Persian",
      "Farsi",
      "Urdu",
      "Yiddish",
      "Kurdish",
      "Pashto",
    ];
    return rtlLanguages.includes(language);
  };

  // Determine text direction based on language
  const isRtl = isRtlLanguage(language);

  // Handle click events on morphemes
  const handleMorphemeClick = (word) => {
    if (!isExpanded) return;
    setSelectedMorphemes((prevSelected) =>
      prevSelected.includes(word)
        ? prevSelected.filter((morpheme) => morpheme !== word)
        : [...prevSelected, word]
    );
  };

  // Rendering Section: Create JSX elements from morpheme objects
  const morphemeElements = message.morphemeArray.map(
    ({
      key,
      originalMorpheme,
      translatedMorpheme,
      transliteratedMorpheme,
      opacity,
      isSelected,
    }) => (
      <div
        key={key}
        className={`translation-pair ${isExpanded ? "message-expanded" : ""} ${
          isSelected ? "selected-morpheme" : ""
        }`}
        onClick={() => handleMorphemeClick(originalMorpheme)}
      >
        <span className="original-word">{originalMorpheme}</span>
        {showTransliteration && (
          <span className="transliteration-word" style={{ opacity }}>
            {transliteratedMorpheme}
          </span>
        )}
        <span className="translated-word" style={{ opacity }}>
          {translatedMorpheme}
        </span>
      </div>
    )
  );

  // Render the container with all morpheme elements
  return (
    <div
      className={`translation-grid ${message.sender} ${
        isExpanded ? "message-expanded" : ""
      } ${isRtl ? "rtl" : "ltr"}`}
    >
      {morphemeElements}
    </div>
  );
};

export default ConversationArea;
