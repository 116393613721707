import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../../App.css';
import './RegisterAndLoginPages.css';

function LoginPage() {
	return (
		<div className="App">
			<LoginManager />
		</div>
    );
}

const LoginManager = () => {
	const navigate = useNavigate();

	const [errorMessage, setErrorMessage] = useState('');
	const [isLoading, setIsLoading] = useState(false); // Optional: For loading state

	const [username, setUsername] = useState(localStorage.getItem('username') || '');
	const [theme, setTheme] = useState(localStorage.getItem('theme') || 'dark');

	const usernameInputRef = useRef(null); // Reference for the username input field
	const passwordInputRef = useRef(null); // Reference for the password input field

	useEffect(() => {
		import(`../../themes/${theme}.css`)
			.then(() => {
			})
			.catch(err => {
				console.error(`Failed to load ${theme} theme`, err);
			});
		document.body.className = theme;
	}, [theme]); // Re-run effect when theme changes

	async function loginUser(username, password) {
		setIsLoading(true); // Start loading
		setErrorMessage(''); // Clear previous errors
		try {
		  const response = await fetch(`${process.env.REACT_APP_API_URL}/login`, {
			method: 'POST',
			headers: {
			  'Content-Type': 'application/json'
			},
			credentials: 'include',
			body: JSON.stringify({ username, password })
		  });
	
		  const result = await response.json();
	
		  if (response.ok) {
			if (result.loginSuccess) {
			  // Login successful
			  navigate('/app'); // Navigate to the app
			} else {
			  // Login failed - display generic error message
			  setErrorMessage(result.message || 'Invalid username or password.');
			}
		  } else {
			// Handle different HTTP status codes if necessary
			if (response.status === 401) {
			  setErrorMessage(result.message || 'Invalid username or password.');
			} else if (response.status === 400) {
			  setErrorMessage(result.message || 'Please provide both username and password.');
			} else if (response.status === 500) {
			  setErrorMessage(result.message || 'An unexpected error occurred. Please try again later.');
			} else {
			  setErrorMessage('An error occurred. Please try again.');
			}
		  }
		} catch (error) {
		  console.error("Error logging in:", error);
		  setErrorMessage('Unable to connect to the server. Please check your internet connection and try again.');
		} finally {
		  setIsLoading(false); // End loading
		}
	  }

	const handleSubmit = async (e) => {
		e.preventDefault(); // Prevent default form submission behavior

		const username = usernameInputRef.current.value; // Get the current value of the username input
		const password = passwordInputRef.current.value; // Get the current value of the password input

		loginUser(username, password);
	};

	return (
		<>
			<div className="login-container">
				<h1>Welcome Back!</h1>
				<form id="loginUsernameForm" className="username-form" method="dialog" onSubmit={handleSubmit}>
					<div className="input-container">
						<md-outlined-text-field
							label="Enter Your Username"
							id="loginUsernameInput"
							value={username}
							ref={usernameInputRef}
							placeholder="e.g., JohnDoe123"
							required
							editable
							aria-label="Username"
						/>
					</div>
					<div className="input-container">
						<md-outlined-text-field
							label="Enter Your Password"
							id="loginPasswordInput"
							type="password"
							ref={passwordInputRef}
							placeholder="Password"
							required
							editable
							aria-label="Password"
						/>
					</div>
					<div className="form-actions">
						<md-filled-button type="submit">
							Continue
						</md-filled-button>
					</div>
				</form>
				{errorMessage && <div className="error-message">{errorMessage}</div>}
				<a href="#">Forgot password?</a>
				<span>Don't have an account? <Link to="/register">Sign up</Link></span>
				<Link to="/">Homepage</Link>
			</div>
		</>
	);
}

export default LoginPage;